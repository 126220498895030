<template>
    <div class="approved-container">
        <div class="approved-content">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 3">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackFour">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackThree">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">小测列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{paperName}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{studentName}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">小测列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{paperName}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-scrollbar class="approved-detail" style="height: 100%" :native="false">
                <div class="detail-top">
                    <div class="top-left">
                        <p class="title">{{paperName}}</p>
                        <p class="info">
                            答题人：{{studentName}}
                            交卷时间：{{paperDetail.submitTime}}
                        </p>
                    </div>
                    <div class="top-right">
                        <span class="approved-status">已批阅</span>
                    </div>
                </div>
                <div class="detail-data">
                    <div class="data-left">
                        <div class="left-icon">
                            <i class="iconfont">&#xe724;</i>
                        </div>
                        <div class="left-info">
                            <span class="score">总分：{{paperDetail.paperScore}}分</span>
                            <span class="score">
                                得分：{{paperDetail.studentScore}}分
                            </span>
                        </div>
                    </div>
                    <div class="data-table">
                        <div class="row">
                            <div class="item"></div>
                            <div class="item">答对</div>
                            <div class="item">答错</div>
                            <div class="item">未答</div>
                            <div class="item">得分</div>
                        </div>
                        <div class="row" :class="{'row-2': !scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered}" v-for="(scoreItem, scoreKey) in scoreList">
                            <div class="item">
                                {{scoreItem.title}}
                                <span class="gray">({{scoreItem.count}}道题)</span>
                            </div>
                            <div class="item" v-if="scoreItem.right > -1">{{scoreItem.right}} 道</div>
                            <div class="item" v-if="scoreItem.wrong > -1">{{scoreItem.wrong}} 道</div>
                            <div class="item" v-if="scoreItem.unanswered > -1">{{scoreItem.unanswered}} 道</div>
                            <div class="item" :class="{'item-full': !scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered}">
                                <span v-if="!scoreItem.right && !scoreItem.wrong && !scoreItem.unanswered">得分：</span>
                                <span>{{scoreItem.score}}分</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-question">
                    <div class="question-type-item" v-for="(questionTypeItem, questionItemKey, questionTypeIndex) in questionTypeList">
                        <div class="question-type-title">
                            <span class="title">{{questionTypeIndex + 1 | formatNum}}、{{questionItemKey | formatTestType}}</span>
                            <span class="subtitle">(共{{questionTypeItem.length}}题，共{{getTypeTotal(questionTypeItem)}}分)</span>
                        </div>
                        <div class="subject-item" v-for="(subjectItem, subjectIndex) in questionTypeItem">
                            <div class="subject-answer">
                                <div class="subject-left">
                                    <div class="subject-title">
                                        <span>{{subjectIndex + 1}}.</span>
                                        <span class="score">({{subjectItem.score}}分)</span>
                                        <span class="title" v-html="subjectItem.topic.title"></span>
                                    </div>
                                    <el-radio-group v-model="subjectItem.topic.options.value" class="answer-others" v-if="questionItemKey === 'single' || questionItemKey === 'judge'">
                                        <el-radio :label="answerIndex" class="answer-item"
                                                  v-for="(answerItem, answerIndex) in subjectItem.topic.options.option">
                                            <span>{{answerIndex + 1 | formatNumber}}、</span>{{answerItem.name}}
                                        </el-radio>
                                    </el-radio-group>
                                    <el-checkbox-group v-model="subjectItem.topic.options.value" class="answer-others" @change="multipleSort" v-if="questionItemKey === 'multiple'">
                                        <el-checkbox :label="answerIndex" class="answer-item" v-for="(answerItem, answerIndex) in subjectItem.topic.options.option">
                                            {{answerIndex + 1 | formatNumber}}、{{answerItem.name}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                    <div class="answer-others" v-if="questionItemKey === 'fillBlank'">
                                        <div class="answer-item" v-for="(answerItem, answerIndex) in subjectItem.topic.options.option" style="color: #409eff; font-weight: 500">
                                            {{answerIndex + 1 | formatNumber}}、{{answerItem.name}}
                                        </div>
                                    </div>
                                    <div class="answer-wrapper" v-if="questionItemKey === 'askAnswer'">
                                        <div class="answer-box">
                                            <div class="wrapper-left">
                                                <span class="answer-tags">学生答案</span>
                                            </div>
                                            <div class="answer-content">
                                                <div class="answer">{{subjectItem.topic.options.student_value}}</div>
                                            </div>
                                        </div>
                                        <div class="answer-box">
                                            <div class="wrapper-left">
                                                <span class="answer-tags">老师评分</span>
                                            </div>
                                            <div class="answer-content">
                                                <div class="answer" style="line-height: 32px">{{subjectItem.student_score}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="student-answer" v-if="questionItemKey === 'single' || questionItemKey === 'judge'">
                                        正确答案是：<strong>{{subjectItem.topic.options.value + 1 | formatNumber}}</strong>
                                        你的答案是：<strong :class="{current: subjectItem.topic.options.value !== subjectItem.topic.options.student_value}">{{subjectItem.topic.options.student_value + 1 | formatNumber}}</strong>&nbsp;
                                        得：<strong>{{subjectItem.student_score}}</strong>分
                                    </div>
                                    <div class="student-answer" v-if="questionItemKey === 'multiple'">
                                        正确答案是：<strong v-for="valueItem in subjectItem.topic.options.value">{{valueItem + 1 | formatNumber}}</strong>
                                        你的答案是：<strong v-for="(stuAnItem,index) in subjectItem.topic.options.student_value" :class="{current:isMultipleErr(subjectItem.topic.options.value,stuAnItem) === true}">{{stuAnItem + 1 | formatNumber}} </strong>&nbsp;
                                        得：<strong>{{subjectItem.student_score}}</strong>分
                                    </div>
                                    <div class="student-answer" v-if="questionItemKey === 'fillBlank'">
                                        正确答案是：<strong v-for="valueItem in subjectItem.topic.options.option">{{valueItem.name}}</strong>
                                        你的答案是&nbsp;<strong v-for="(stuAnItem,index) in subjectItem.topic.options.student_value" :class="{current: !subjectItem.topic.options.option.includes(stuAnItem)}">{{stuAnItem}} </strong>&nbsp;
                                        得：<strong>{{subjectItem.student_score}}</strong> 分
                                    </div>
                                </div>
                                <div class="subject-right">
                                    <el-button type="primary" size="medium" @click="subjectItem.showAnalysis = !subjectItem.showAnalysis">
                                        题目解析<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                </div>
                            </div>
                            <div class="subject-analysis" v-show="subjectItem.showAnalysis">
                                题目解析：<span v-html="subjectItem.topic.analyse"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        filters: {
            formatNum(type) {
                const types = {
                    1: '一',
                    2: '二',
                    3: '三',
                    4: '四',
                    5: '五',
                    6: '六',
                    7: '七',
                    8: '八',
                    9: '九',
                    10: '十',
                }
                return types[type]
            },
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
            formatTestType(type) {
                const types = {
                    'single': '单选题',
                    'multiple': '多选题',
                    'judge': '判断题',
                    'fillBlank': '填空题',
                    'askAnswer': '问答题',
                }
                return types[type]
            }
        },
        data() {
            return {
                quizId: Number(this.$route.query.quizId ) || '',
                classId: Number(this.$route.query.classId ) || '',
                studentId: Number(this.$route.query.studentId ) || '',
                className: this.$route.query.className || '',
                paperName: this.$route.query.paperName || '',
                studentName: this.$route.query.studentName || '',
                scoreList: [],
                paperDetail: {
                    paperScore: '',
                    studentScore: '',
                    submitTime: '',
                    // quizRecordId: '',
                },
                questionTypeList: {},
                role: Number(localStorage.getItem('role'))
            }
        },
        mounted() {
            this.getApprovedDetail();
        },
        methods: {
            isMultipleErr(arr,value){
              let isErr = arr.find(item=>item === value);
              if(isErr !== undefined){
                return false
              } else {
                return true
              }
            },
            getTypeTotal(item) {
                let total = 0
                item.forEach(i => {
                    total += Number(i.score)
                })
                return total
            },
            getApprovedDetail() {
                let params = {
                    quiz_id: this.quizId,
                    s_id: this.studentId,
                }
                this.$shttp.axiosGetBy(this.$api.seeStudentQuiz, params, (res) => {
                    if (res.code === 200) {
                        console.log(res)
                        this.scoreList = res.data.info;
                        this.paperDetail.paperScore = res.data.paper_total;
                        this.paperDetail.submitTime = res.data.submit_time;
                        this.paperDetail.studentScore = res.data.score;
                        this.paperDetail.quizRecordId = res.data.quiz_record_id;
                        let paperContent = res.data.result
                        for (let key in paperContent) {
                            paperContent[key].forEach(item => {
                                item.showAnalysis = false
                            })
                        }
                        this.questionTypeList = paperContent;
                        this.multipleSort()
                    }

                }, (err) => {
                    console.log(err)
                })
            },
            multipleSort(){
              if(this.questionTypeList['multiple']){
                if(this.questionTypeList['multiple'].length){
                  this.questionTypeList['multiple'].forEach(item=>{
                    item.topic.options.value = item.topic.options.value.sort((a,b)=>a - b)
                    item.topic.options.student_value = item.topic.options.student_value.sort((a,b)=>a - b)
                  })
                }
              }
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
            goBackFour() {
                this.$router.go(-4)
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .approved-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .approved-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .approved-detail {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .detail-top {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .top-left {
                        width: 1%;
                        flex: 1;
                        p {
                            margin: 0;
                            &.title {
                                font-size: 16px;
                            }
                            &.info {
                                color: #666;
                            }
                        }
                    }
                    .top-right {
                        margin-left: 20px;
                        .approved-status {
                            color: #fff;
                            display: inline-block;
                            padding: 0 20px;
                            line-height: 32px;
                            background: #F01414;
                            border-radius: 16px;
                        }
                    }
                }
                .detail-data {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .data-left {
                        color: #fff;
                        padding: 40px 20px;
                        margin-right: 20px;
                        background: linear-gradient(49deg,rgba(107, 192, 255, 1),rgba(58, 140, 246, 1));
                        box-shadow: 0 4px 8px 0 rgba(20,55,186,0.2);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .left-icon {
                            width: 50px;
                            height: 50px;
                            background: #fff;
                            border-radius: 10px;
                            line-height: 50px;
                            text-align: center;
                            .iconfont {
                                color: #409eff;
                                font-size: 28px;
                            }
                        }
                        .left-info {
                            margin-left: 10px;
                            span {
                                display: block;
                                &.score {
                                    font-size: 18px;
                                    line-height: 1.3;
                                    font-weight: 500;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .data-table {
                        width: 1%;
                        flex: 1;
                        display: flex;
                        border-left: 1px solid #EAEAEA;
                        border-bottom: 1px solid #EAEAEA;
                        .row {
                            flex: 1;
                            text-align: center;
                            .item {
                                min-height: 21px;
                                padding: 10px;
                                border-top: 1px solid #EAEAEA;
                                border-right: 1px solid #EAEAEA;
                                .gray {
                                    color: #999;
                                }
                            }
                            &.row-2 {
                                display: flex;
                                flex-direction: column;
                                .item-full {
                                    height: 1%;
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
                .detail-question {
                    .question-type-item {
                        padding: 20px 20px 0;
                        .question-type-title {
                            .title {
                                font-size: 18px;
                                color: #333;
                                font-weight: bold;
                            }
                            .subtitle {
                                font-size: 16px;
                                color: #666;
                            }
                        }
                        .subject-item {
                            padding-bottom: 20px;
                            border-bottom: 1px solid #EAEAEA;
                            .subject-answer {
                                display: flex;
                                margin-top: 15px;
                                .subject-left {
                                    width: 1%;
                                    flex: 1;
                                    .subject-title {
                                        font-size: 16px;
                                        color: #333;
                                        font-weight: 500;
                                        span {
                                            ::v-deep p {
                                                margin: 0;
                                                display: inline;
                                            }
                                            &.score {
                                                color: #409eff;
                                                opacity: 0.7;
                                                margin: 0 10px;
                                            }
                                        }
                                    }
                                    .answer-wrapper {
                                        .answer-box {
                                            display: flex;
                                            margin-top: 10px;
                                        }
                                        .answer-tags {
                                            color: #FF0000;
                                            background: #FFDFDF;
                                            display: inline-block;
                                            padding: 6px 10px;
                                            border-radius: 4px;
                                        }
                                        .answer-content {
                                            width: 1%;
                                            flex: 1;
                                            margin-left: 20px;
                                            .answer {
                                                color: #409eff;
                                                font-weight: 500;
                                            }
                                            .score {
                                                margin-top: 20px;
                                            }
                                        }
                                    }
                                    .answer-others {
                                        display: block;
                                        margin-top: 15px;
                                        padding-left: 20px;
                                        .answer-item {
                                            display: block;
                                            margin-top: 6px;
                                            cursor: default;
                                            &.current {
                                                color: #409EFF;
                                                font-weight: bold;
                                            }
                                        }
                                        .el-radio {
                                            display: flex;
                                            .el-radio__label {
                                                width: 1%;
                                                flex: 1;
                                                white-space: normal;
                                                line-height: 1.2;
                                            }
                                        }
                                    }
                                    .student-answer {
                                        margin-top: 15px;
                                        padding-left: 20px;
                                        strong {
                                            font-size: 18px;
                                            color: #409eff;
                                            margin-right: 10px;
                                            &.current {
                                                color: #F01414;
                                            }
                                        }
                                        &.current {
                                            strong {
                                                color: #F01414;
                                            }
                                        }
                                    }
                                }
                                .subject-right {
                                    margin-left: 20px;
                                }
                            }
                            .subject-analysis {
                                margin: 15px 0 0;
                                background: #F5F5F5;
                                padding: 15px;
                                span {
                                    ::v-deep p {
                                        margin: 0;
                                        display: inline;
                                    }
                                }
                            }
                        }
                    }
                    .detail-commit {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
</style>